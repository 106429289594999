import React, { useEffect, useState } from "react";
import * as s from "./BackToBlog.module.scss";
import BackTo from "../../atoms/BackToBlog/BackTo";
import AskInput from "../../atoms/AskInput/AskInput";

interface IBackToBlog {
  image?: string;
}
const BackToBlog = ({ image }: IBackToBlog) => {
  const [img, setImg] = useState<string | undefined>("");

  useEffect(() => {
    setImg(image);
  }, [image]);

  return (
    <div className={s.container}>
      <div className={s.first}>
        <div className={s.second}>
          <BackTo section="backto" />
        </div>
        <div className={s.third}>
          <AskInput placeholder="Ask us anything" />
        </div>
      </div>
      <img
        src={img}
        alt=""
        style={{ maxHeight: "372px", objectFit: "cover" }}
      ></img>
    </div>
  );
};

export default BackToBlog;
