import React, { useEffect, useState } from "react";
import * as s from "./Blog.module.scss";
import BackTo from "../../atoms/BackToBlog/BackTo";
import moment from "moment";

interface IBlogProps {
  data: ArticleOne;
}

const Blog = ({ data }: IBlogProps) => {
  const [contentHtml, setContentHtml] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (data?.contentHtml)
      setContentHtml(data?.contentHtml?.replace("</p>", "</p><br />"));
  }, [data]);

  return (
    <div className={s.container} id={data?.title}>
      <p className={s.title}>{data?.title}</p>
      <div className={s.first}>
        <p className={s.author}>{data?.authorV2?.name}</p>
        <p className={s.date}>
          {moment(data?.publishedAt).format("MMM DD, YYYY")}
        </p>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: contentHtml,
        }}
        className={s.tekst}
      />
      <div className={s.second}>
        <BackTo section="blog" />
      </div>
    </div>
  );
};

export default Blog;
