import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_BLOGS, GET_BLOGS_PAGINATION } from "../../../g/queries/get-blogs";
import Article from "../../atoms/Article/Article";
import BackToBlog from "../../molecules/BackToBlog/BackToBlog";
import Blog from "../../molecules/Blog/Blog";
import * as s from "./BlogSingleLayout.module.scss";

interface IBlogProps {
  blog: ArticleOne;
  hash?: string;
}

interface ISingleArticle {
  cursor: string;
  node: ArticleOne;
}

const BlogSingleLayout = ({ blog, hash }: IBlogProps) => {
  const [newArticle, setNewArticle] = useState<ArticleOne>();
  const [allBlogData, setAllBlogData] = useState();

  const { data } = useQuery(GET_BLOGS, {
    variables: { query: "news", number: 6 },
  });

  const [getAllBlogData] = useLazyQuery(GET_BLOGS, {
    onCompleted: (data) => {
      setAllBlogData(data);
    },
  });

  const articles = [
    data?.blogs?.edges[0]?.node?.articles?.edges[0],
    data?.blogs?.edges[0]?.node?.articles?.edges[1],
  ];

  const getBlogByHandle = (data: any) => {
    let found = false;
    const allArticles = data?.blogs?.edges[0]?.node?.articles;
    allArticles?.edges.map((article: any) => {
      found = false;
      if (article?.node?.handle === hash?.substring(1)) {
        setNewArticle(article?.node);
        found = true;
      }
    });
    if (!found) {
      const cursor = allArticles?.edges[allArticles?.edges?.length - 1]?.cursor;
      const nextPage = allArticles?.pageInfo?.hasNextPage;
      loadMoreArticles(cursor, nextPage);
    }
  };

  const [getNews] = useLazyQuery(GET_BLOGS_PAGINATION, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      getBlogByHandle(data);
    },
  });

  const loadMoreArticles = (cursor: string, nextPage: boolean) => {
    if (nextPage) {
      getNews({
        variables: {
          query: "news",
          cursor: cursor,
        },
      });
    }
  };

  useEffect(() => {
    getAllBlogData({
      variables: { query: "news", number: 100 },
    });
  }, [hash]);

  useEffect(() => {
    if (blog === undefined) getBlogByHandle(allBlogData);
  }, allBlogData);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 5%",
        maxWidth: "100%",
      }}
      id={hash}
    >
      <BackToBlog
        image={blog?.image?.originalSrc || newArticle?.image?.originalSrc}
      />
      <Blog data={blog || newArticle} />
      <div className={s.article}>
        {(articles || []).map((a: ISingleArticle, i: number) => (
          <Article article={a?.node} key={i} />
        ))}
      </div>
    </div>
  );
};

export default BlogSingleLayout;
