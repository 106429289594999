/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import React from "react";
import moment from "moment";
import * as s from "./Article.module.scss";
import arrowReadMore from "../../../images/arrowReadMore.svg";
import { Link } from "gatsby";
import placeholder from "../../../images/placeholder-image.png";

interface ArticleProps {
  article: IArticle;
}
const Article = ({ article }: ArticleProps) => {
  return (
    <div className={s.article}>
      <Link to={`/blog-single/#${article?.handle}`} state={{ article }}>
        <img
          src={article?.image?.originalSrc || placeholder}
          alt="article"
          className={s.articleImage}
        />
      </Link>
      <Link
        to={`/blog-single/#${article?.handle}`}
        state={{ article }}
        className={s.articleTitle}
      >
        <p className={s.articleTitle}>{article?.title}</p>
      </Link>
      <Link
        to={`/blog-single/#${article?.handle}`}
        state={{ article }}
        className={s.articleText}
      >
        <p className={s.articleText}>{article?.content}</p>
      </Link>
      <div className={s.readMoreWrapper}>
        <div className={s.readMoreTextWrap}>
          <Link
            to={`/blog-single/#${article?.handle}`}
            state={{ article }}
            className={s.readMore}
          >
            <p className={s.readMore}>{`Read more`}</p>
          </Link>
          <img src={arrowReadMore} alt="read-more" />
        </div>

        <p className={s.publishedAt}>
          {moment(article?.publishedAt).format("MMM DD, YYYY")}
        </p>
      </div>
    </div>
  );
};

export default Article;
