import { gql } from "@apollo/client";

// blogs(query: "news", first: 10) {
export const GET_BLOGS = gql`
  query getBlogs($query: String!, $number: Int) {
    blogs(query: $query, first: 10) {
      edges {
        node {
          handle
          title
          articles(first: $number) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                id
                handle
                title
                content
                contentHtml
                authorV2 {
                  name
                  firstName
                  lastName
                }
                image {
                  originalSrc
                }
                publishedAt
                tags
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_BLOGS_PAGINATION = gql`
  query getBlogs($query: String!, $cursor: String!) {
    blogs(query: $query, first: 10) {
      edges {
        node {
          handle
          title
          articles(first: 6, after: $cursor) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                title
                content
                handle
                contentHtml
                authorV2 {
                  name
                  firstName
                  lastName
                }
                image {
                  originalSrc
                }
                publishedAt
              }
            }
          }
        }
      }
    }
  }
`;
