import React from "react";
import search from "../../../images/Group72.png";
import * as s from "./AskInput.module.scss";

const AskInput = ({
  placeholder,
  onChange,
}: {
  placeholder: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
}) => {
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
  };

  return (
    <div className={s.container}>
      <input
        placeholder={placeholder}
        className={s.input}
        onChange={handleChange}
      ></input>
      <div className={s.first}>
        <img src={search} alt="" className={s.img}></img>
      </div>
    </div>
  );
};

export default AskInput;
