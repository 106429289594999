import { Link } from "gatsby";
import React from "react";
import * as s from "./BackTo.module.scss";
import arrow from "../../../images/Arrow - Right.svg";
import classNames from "classnames";

const cn = classNames.bind(s);

const BackTo = ({ section }: { section: string }) => {
  return (
    <>
      <img
        src={arrow}
        className={cn(s.image, { [s.image1]: section === "blog" })}
        alt="arrow"
      ></img>
      <Link to="/blog" className={s.text}>
        Back to <span className={s.span}>blog</span>{" "}
      </Link>
    </>
  );
};

export default BackTo;
