import React, { useEffect } from "react";
import BlogSingleLayout from "../components/layouts/BlogSingleLayout/BlogSingleLayout";
import { PageTitle, SEO } from "../modules/seo";

interface ILocation {
  location: {
    hash: string;
    host: string;
    hostname: string;
    href: string;
    key: string;
    origin: string;
    pathname: string;
    port: string;
    protocol: string;
    search: string;
    state: {
      article: ArticleOne;
      key: string;
    };
  };
}

const BlogSingle = ({ location }: ILocation) => {
  return (
    <>
      <SEO title={PageTitle.BlogSingle} />
      <BlogSingleLayout blog={location?.state?.article} hash={location?.hash} />
    </>
  );
};

export default BlogSingle;
